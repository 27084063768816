<template>
  <div class="user_container">
    <el-row class="user_header">
      <el-col :span="24">物价查询</el-col>
    </el-row>
    <Count></Count>
    <el-row class="tip"> </el-row>
    <div class="classifyList">
      <span v-for="(item, index) in classifyList" :key="index" :class="gaolian == index ? 'active' : ''" @click="changeTab(index)">
        {{ item }}
      </span>
    </div>
    <div class="tips">温馨提示：请输入所需查询的物价拼音字母缩写</div>
    <div class="search">
      <input v-model="searchVal" style="width: 50%;" type="text" />
      <el-button class="inquire" type="success" @click.native="inquire">查询</el-button>
    </div>
    <div class="user_main">
      <el-table v-loading="loading" :data="tableData" border="" style="width: 100%;">
        <!-- <el-table-column label="收费项目编号"
                         prop="name">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="收费项目名称" prop="drugName">
          <template slot-scope="scope">
            <span>{{ scope.row.drugName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="医保类别" prop="lsYbType">
          <template slot-scope="scope">
            <span>{{ scope.row.lsYbType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分类" prop="classify">
          <template slot-scope="scope">
            <span v-if="scope.row.classify == 1">中成药</span>
            <span v-else-if="scope.row.classify == 2">西药</span>
            <span v-else-if="scope.row.classify == 3">中药</span>
            <span v-else-if="scope.row.classify == 4">检验</span>
            <span v-else-if="scope.row.classify == 5">检查</span>
            <span v-else-if="scope.row.classify == 9">耗材</span>
          </template>
        </el-table-column>
        <el-table-column label="单价（元）" prop="price">
          <template slot-scope="scope">
            <span>{{ scope.row.price }}</span>
          </template>
        </el-table-column>

        <el-table-column label="单位" prop="unit">
          <template slot-scope="scope">
            <span>{{ scope.row.unit }}</span
            ><br />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row class="page">
      <el-col :span="24">
        <el-button class="previous" type="primary" @click.native="previous">上一页</el-button>
        <span>第{{ pages }}页&nbsp;&nbsp;共{{ totalCount }}页</span>
        <el-button class="next" type="primary" @click.native="next">下一页</el-button>
      </el-col>
    </el-row>

    <!-- <div class="tip">
      <h1>温馨提示：默认查询30天内的挂号记录</h1>
    </div> -->
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Count from '../count/index'
import Foo from '../footer/index'
import qs from 'qs'
import { jsonPost, formPost } from '@/baseAPI'

export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      date: '',
      end: '',
      query: {},
      tableData: [],
      loading: false,
      totalCount: '0',
      pages: '1',
      classifyList: ['所有类别', '中成药', '西药', '中药', '检验', '检查', '耗材'],
      currentIndex: 0,
      gaolian: 0,
      searchVal: ''
    }
  },
  created() {
    this.query = this.$route.query
    this.date = this.formatTime(-30)
    this.end = this.formatTime(0)
    this.getDrugInfo()
  },
  methods: {
    changeTab(index) {
      if (index == 6) {
        this.currentIndex = 9
        this.gaolian = 6
      } else {
        this.currentIndex = index
        this.gaolian = index
      }
      this.pages = '1'
      this.tableData = []
      this.getDrugInfo()
    },
    // 查询
    inquire() {
      this.pages = '1'
      this.getDrugInfo()
    },
    previous() {
      if (this.pages == '1') {
        this.$message({
          message: '已经是第一页了！',
          type: 'warning'
        })
      } else {
        this.pages = this.pages - 1
        this.getDrugInfo()
      }
    },
    next() {
      if (this.pages >= this.totalCount) {
        this.$message({
          message: '已经是最后一页了！',
          type: 'warning'
        })
      } else {
        this.pages = Number(this.pages) + 1
        this.getDrugInfo()
      }
    },
    // 物价查询
    async getDrugInfo() {
      this.loading = true
      const { data: res } = await jsonPost('/his/getDrugInfo', {
        drugName: this.searchVal,
        classify: this.currentIndex,
        pages: this.pages,
        number: '10'
      })
      if (res.code === 0) {
        this.loading = false
        this.tableData = res.data.drug
        this.totalCount = Math.round(res.data.totalCount / 10)
      } else if (res.code === 500 && res.msg === '运行时异常:null') {
        this.$message({
          message: '暂无数据',
          type: 'warning'
        })
        this.loading = false
      }
    },
    home() {
      this.$router.push('/home')
    },
    recordList() {
      this.loading = true
      let _this = this
      let params = {
        endTime: this.end,
        startTime: this.date,
        patCardNo: this.query.patCardNo,
        recordType: 1
      }
      Promise.all([_this.$api.recordList(params)]).then(data => {
        this.tableData = data[0].data
        this.loading = false
        // this.tableData = data[0].data.filter((item) => item.isPay == 0 || item.isPay == 1 && item.isActive == 0)
      })
    },
    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear() //年
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 //月
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate() //日
      return [y, m, d].map(this.padStartConvert).join('-')
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    }
  }
}
</script>
<style lang="less">
.user_container {
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.user_header {
  background-color: #81ace4;
  height: 60px;
  font-size: 32px;
  font-weight: 700;
  line-height: 60px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.tip h1 {
  font-weight: 400;
  color: red;
  text-align: center;
  font-size: 34px;
}

.classifyList {
  margin-top: 45%;
  padding-left: 28px;
}

.classifyList span {
  width: 100%;
  height: 30px;
  background-color: #81ace4;
  margin-left: 20px;
  padding: 40px;
  font-size: 35px;
  border-radius: 5px;
}

.classifyList .active {
  background-color: #0359c8;
  color: #fff;
}

.tips {
  margin-top: 80px;
  margin-left: 50px;
  font-size: 30px;
  color: red;
}

.search {
  margin-top: 2%;
  padding-left: 50px;
  height: 80px;
  display: flex;
}

.search .inquire {
  margin-left: 20px;
}

.user_main {
  max-height: 65%;
  margin: 4% 50px 0 50px;
  overflow: auto;
  background-color: #fff;
}

.user_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.user_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
}

.user_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.page {
  margin-top: 40px;
  text-align: center;
  font-size: 40px;
}

.page span {
  margin: 0 40px;
}

.page .previous,
.page .next {
  height: 100px;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
